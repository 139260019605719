import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Collecting metrics";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "collecting-metrics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#collecting-metrics",
        "aria-label": "collecting metrics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Collecting metrics`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#collecting-server-side-metrics"
        }}>{`Collecting server-side metrics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#collecting-client-side-metrics"
        }}>{`Collecting client-side metrics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#changing-the-default-distribution-summary-config"
        }}>{`Changing the default distribution summary config`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#excluding-certain-meters-created-by-armeria"
        }}>{`Excluding certain meters created by Armeria`}</a></li>
    </ul>
    <p>{`Armeria has built-in support for collecting metrics both on the server and client side.
This page describes how to enable this.`}</p>
    <Tip mdxType="Tip">
      <p>{`The metric data is collected using the `}<a parentName="p" {...{
          "href": "https://micrometer.io/"
        }}>{`Micrometer`}</a>{` library.
Please consult its documentation for information on how to expose the collected metrics to
various monitoring systems such as `}<a parentName="p" {...{
          "href": "https://prometheus.io/"
        }}>{`Prometheus`}</a>{`,
`}<a parentName="p" {...{
          "href": "https://metrics.dropwizard.io/4.1.2/"
        }}>{`Dropwizard Metrics`}</a>{` and `}<a parentName="p" {...{
          "href": "https://www.datadoghq.com/"
        }}>{`Datadog`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "collecting-server-side-metrics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#collecting-server-side-metrics",
        "aria-label": "collecting server side metrics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Collecting server-side metrics`}</h2>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "type://MetricCollectingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/MetricCollectingService.html"
      }}>{`type://MetricCollectingService`}</a>{` with `}<a parentName="p" {...{
        "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
      }}>{`type://MeterIdPrefixFunction`}</a>{` for collecting metrics
of your services.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.MeterIdPrefixFunction;
import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.metric.MetricCollectingService;

Server.builder()
      .http(new InetSocketAddress(address, port))
      .service(new MyHttpService())
      .decorator(MetricCollectingService.newDecorator(
              MeterIdPrefixFunction.ofDefault("http.service")))
      .build();
`}</code></pre>
    <p>{`If you are interested in monitoring `}<a parentName="p" {...{
        "href": "https://grpc.github.io/grpc/core/md_doc_statuscodes.html"
      }}>{`gRPC status`}</a>{`
for a gRPC service, you can use `}<a parentName="p" {...{
        "href": "type://GrpcMeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcMeterIdPrefixFunction.html"
      }}>{`type://GrpcMeterIdPrefixFunction`}</a>{` instead of `}<a parentName="p" {...{
        "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
      }}>{`type://MeterIdPrefixFunction`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.grpc.GrpcMeterIdPrefixFunction;
import com.linecorp.armeria.server.grpc.GrpcService;

Server.builder()
      .http(new InetSocketAddress(address, port))
      .service(GrpcService.builder()
                          .addService(new MyHelloService())
                          .build())
      .decorator(MetricCollectingService.newDecorator(
              GrpcMeterIdPrefixFunction.of("grpc.service")))
      .build();
`}</code></pre>
    <p>{`You can provide `}<a parentName="p" {...{
        "href": "type://GrpcMeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcMeterIdPrefixFunction.html"
      }}>{`type://GrpcMeterIdPrefixFunction`}</a>{` as a bean if you are using a Spring integration module:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Bean
public MeterIdPrefixFunction grpcMeterIdPrefixFunction() {
    return GrpcMeterIdPrefixFunction.of("grpc.service");
}
`}</code></pre>
    <p>{`In cases where more sophisticated filtering and/or mangling of the generated metrics are required,
you can use the `}<a parentName="p" {...{
        "href": "type://ServerBuilder#meterRegistry(MeterRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#meterRegistry(io.micrometer.core.instrument.MeterRegistry)"
      }}>{`type://ServerBuilder#meterRegistry(MeterRegistry)`}</a>{` method like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Server.builder()
      // ...
      .meterRegistry(myMeterRegistry);
`}</code></pre>
    <h2 {...{
      "id": "collecting-client-side-metrics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#collecting-client-side-metrics",
        "aria-label": "collecting client side metrics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Collecting client-side metrics`}</h2>
    <p>{`This approach can be used to collect metrics for a `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` and a gRPC client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.client.grpc.GrpcClients;
import com.linecorp.armeria.client.metric.MetricCollectingClient;

// Decorate a WebClient with MetricCollectingClient
WebClient.builder(httpUrl)
         .decorator(MetricCollectingClient.newDecorator(
                 MeterIdPrefixFunction.ofDefault("http.client")))
         .build();

// Decorate a gRPC client with MetricCollectingClient
GrpcClients.builder(grpcUrl)
           .decorator(MetricCollectingClient.newDecorator(
                   GrpcMeterIdPrefixFunction.of("grpc.client")))
           .build(HelloServiceBlockingStub.class);
`}</code></pre>
    <p>{`Like the server-side metrics described above, certain scenarios might require you to
provide a custom meter registry. To accomplish this, override the `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` in this way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientFactory;
import com.linecorp.armeria.client.Clients;

ClientFactory clientFactory =
      ClientFactory.builder()
                   .meterRegistry(myMeterRegistry)
                   .build();

// Set a custom ClientFactory to a WebClient
WebClient.builder(httpUrl)
         .factory(clientFactory)
          // ...
         .build();

// Set a custom ClientFactory to a gRPC client
Clients.builder(grpcUrl)
       .factory(clientFactory)
        // ...
       .build(HelloServiceBlockingStub.class);
`}</code></pre>
    <h2 {...{
      "id": "changing-the-default-distribution-summary-config",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#changing-the-default-distribution-summary-config",
        "aria-label": "changing the default distribution summary config permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Changing the default distribution summary config`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://micrometer.io/docs/concepts#_distribution_summaries"
      }}>{`distribution summary`}</a>{` is used to track
the distribution of events. Armeria provides a sensible default
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/io.micrometer/micrometer-core/latest/io/micrometer/core/instrument/distribution/DistributionStatisticConfig.html"
      }}>{`DistributionStatisticConfig`}</a>{`
for measuring the following metrics:`}</p>
    <ul>
      <li parentName="ul">{`A length of the request content`}</li>
      <li parentName="ul">{`A length of the response content`}</li>
      <li parentName="ul">{`A duration of request`}</li>
      <li parentName="ul">{`A duration of response`}</li>
    </ul>
    <p>{`If you want to override the default config,
you can set your own `}<inlineCode parentName="p">{`DistributionStatisticConfig`}</inlineCode>{` using `}<a parentName="p" {...{
        "href": "type://MoreMeters#setDistributionStatisticConfig(DistributionStatisticConfig):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MoreMeters.html#setDistributionStatisticConfig(io.micrometer.core.instrument.distribution.DistributionStatisticConfig)"
      }}>{`type://MoreMeters#setDistributionStatisticConfig(DistributionStatisticConfig)`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.metric.MoreMeters;
import io.micrometer.core.instrument.distribution.DistributionStatisticConfig;

DistributionStatisticConfig myDistStatCfg =
        DistributionStatisticConfig.builder()
                                   .percentilesHistogram(false)
                                   .sla()
                                   .percentiles({ 0, 0.5, 0.75, 0.9, 0.95, 0.99, 1.0 })
                                   .percentilePrecision(10)
                                   .minimumExpectedValue(1L)
                                   .maximumExpectedValue(Long.MAX_VALUE)
                                   .expiry(Duration.ofMinutes(10))
                                   .bufferLength(10)
                                   .build();
MoreMeters.setDistributionStatisticConfig(myDistStatCfg);
`}</code></pre>
    <h2 {...{
      "id": "excluding-certain-meters-created-by-armeria",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#excluding-certain-meters-created-by-armeria",
        "aria-label": "excluding certain meters created by armeria permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Excluding certain meters created by Armeria`}</h2>
    <p>{`Micrometer's `}<inlineCode parentName="p">{`MeterRegistry`}</inlineCode>{` can be configured with `}<a parentName="p" {...{
        "href": "https://micrometer.io/docs/concepts#_meter_filters"
      }}>{`meter filters`}</a>{`.
If you need to control the exported meters, you can apply sophisticated filters to the `}<inlineCode parentName="p">{`MeterRegistry`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.Flags;

Flags.meterRegistry()
     .config()
     .meterFilter(MeterFilter.deny(id ->
                  id.getTag("service").equals("MyHealthCheckService")));
     .meterFilter(MeterFilter.denyNameStartsWith("jvm"));
`}</code></pre>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
      }}>{`type://MeterIdPrefixFunction`}</a>{` to learn what kinds of tags are used for request metrics.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      